.top-header {
    position: absolute;
    justify-content: center;
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    padding: 10px;
}

.home-works-cards1 {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    padding: 0;
    justify-content: center;
    margin: 0;
    margin-top: 100px;
    margin-bottom: 100px;
}

@media only screen and (max-width: 600px) {
    .home-works-cards1 {
        margin-top: 50px;
    }
}

@media (min-width:600px) and (max-width:1000px) {
    .home-works-cards1 {
        margin-top: 70px;
    }

}