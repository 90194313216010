.title-about {
    font-family: 'Quicksand';
    font-size: 40px;
    letter-spacing: 5px;
    margin-top: 100px;
    color: black;
    opacity: 0.5;
    text-align: right;

}

.title-1 {
    font-family: 'Quicksand';
    font-size: 22px;
    letter-spacing: 3px;
    margin-top: 30px;
    text-align: right;

}

.title-2 {
    font-family: 'Quicksand';
    font-size: 22px;
    letter-spacing: 3px;
    text-align: right;
    margin-bottom: 20px;
}

.about-me-sub {
    font-family: 'Quicksand';
    font-size: 25px;
    letter-spacing: 3px;
    margin-bottom: 30px;
    margin-top: 20px;
}

.about-me-p {
    font-family: 'Quicksand';
    text-align: justify;
    letter-spacing: 1px;
    font-size: 20px;

}

.about-me-start {
    font-family: 'Quicksand';
    text-align: center;
    letter-spacing: 1px;
    font-size: 20px;
    margin-bottom: 10;
}

@media only screen and (max-width: 600px) {
    .title-about {
        font-size: 25px;
        margin-top: 50px;
        padding-right: 20px;
    }

    .title-1 {
        font-size: 14px;
        padding-right: 20px;
        margin-top: 20px;
    }

    .title-2 {
        font-size: 14px;
        padding-right: 20px;
        padding-left: 20px;
    }

    .about-me-sub {
        font-size: 16px;
        margin-bottom: 10px;
        margin-top: 20px;
        padding-left: 20px;
    }

    .about-me-p {
        font-size: 10px;
        padding-right: 20px;
        padding-left: 20px;
    }

    .about-me-start {
        font-size: 10px;
        padding-right: 20px;
        padding-left: 20px;
    }

}

@media (min-width:600px) and (max-width:1000px) {}