.navbar {
    padding-top: 40px;

}

.brand-part {
    font-size: 40px;
    font-family: 'Covered By Your Grace' !important;
    font-weight: 400;
    line-height: 30px;
    color: #000;
    opacity: 0.6;

}

.nav-item {
    color: rgba(255, 255, 255, 1) !important;
    font-size: 16px;
    font-family: 'Oswald';
    font-weight: 500;
    line-height: 18px;

}

.navbar-toggler {
    border: 1px solid #fdfdfd75;
}

.dropdown-item-title {
    color: #010101;
    font-family: 'Oswald' !important;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
}

.dropdown-item-list {
    color: #010101;
    opacity: 70%;
    font-family: 'Oswald' !important;
    font-style: normal;
    font-weight: 500;
    font-size: 1px;
    line-height: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.nav-link-left {
    color: #fff;
    padding-left: 0;
    padding-right: 60px;
    opacity: 0.6;

}

.nav-link:focus {
    color: #000;
}

.nav-link:hover {
    color: #000;
}

.brand-part:hover {
    color: #fff;
}

.dropdown-toggle:focus {
    color: #010101;
    background-color: rgba(255, 255, 255, 1) !important;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.dropdown-menu {
    border-top-right-radius: 0;
    border-color: #fff !important;
    border-top-left-radius: 5px;
}

.dropdown-toggle {
    border: none;
}

.dropdown-divider {
    color: #fdfdfd30;
}

@media only screen and (max-width: 600px) {
    .navbar {
        padding-top: 10px;


    }

    .dropdown-menu {
        border-top-left-radius: 0;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
        border: #fdfdfd9a solid 0.3px;
        padding: 0;
    }

    .dropdown-toggle:focus {
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
    }

    .nav-item {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 12px;
        line-height: 1px;
        margin-top: 0;
        background-color: #00000074;
    }

    .dropdown-menu {
        background-color: #fdfdfd6c;

    }

    .dropdown-item {
        padding: 0;
        padding-left: 10px;
        font-size: 8px;
        line-height: 12px;
    }

    .dropdown-item-title {
        font-size: 9px;
    }

    .dropdown-divider {

        margin: 0;
    }

    .brand-part {
        font-size: 30px;
    }

}

@media (min-width:600px) and (max-width:1000px) {

    .dropdown-menu {
        border-top-left-radius: 0;

    }

    .dropdown-toggle:focus {
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
    }

    .dropdown-menu {
        background-color: #fdfdfd6c;

    }

    .nav-item {
        padding-left: 15px;
        padding-right: 15px;
        font-size: 18px;
        line-height: 15px;
        margin-top: 0;
        background-color: #00000074;

    }

    .dropdown-menu {
        background-color: #fdfdfd6c;

    }

    .dropdown-item {
        font-size: 16px;
        padding: 0;
        padding-left: 10px;
    }
}