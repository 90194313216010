.images-bg {
    border-radius: 10px;
    height: 300px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    border: 1px solid #fff;
    text-align: center;
}

.overlay {
    position: absolute;
    border-radius: 10px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
    width: auto;
    opacity: 0;
    transition: .5s ease;
    background-color: black;
}

.images-bg:hover .overlay {
    opacity: 0.7;
    cursor: pointer;
}

.text {
    color: #fff;
    font-size: 24px;
    position: absolute;
    top: 40%;
    left: 50%;
    width: 80%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    font-family: 'Oswald';
    letter-spacing: 3px;
}

.sub-text {
    color: #fff;
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    height: 50px;
    text-align: center;
    padding-top: 10px;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    font-family: 'Oswald';
}

.works-name {
    color: #fff;
    background-color: #00000061;
    font-family: 'Oswald';
    position: absolute;
    right: 0%;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.title-s {
    letter-spacing: 3px;
    font-size: 16px;
    margin-top: 120px;
    margin-bottom: 0px;

}

.subtitle-s {
    font-size: 11px;
    padding-top: 0px;
}

@media only screen and (max-width: 600px) {}

@media (min-width:600px) and (max-width:1000px) {}