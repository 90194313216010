.title-collective {
    padding-top: 100px;
    font-family: 'Oswald';
    font-size: 20px;
    letter-spacing: 1px;
    display: flex;
    justify-content: center;
    padding-bottom: 25px;
    text-align: center;
    opacity: 0.5;
}

@media only screen and (max-width: 600px) {
    .title-collective {
        font-size: 16px;
    }
}

@media (min-width:600px) and (max-width:1000px) {
    .title-collective {
        font-size: 20px;
    }
}