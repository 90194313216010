.contact-img {
    width: 100%;
    height: 100vh;
    background-image: url('../../images/Contact.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.header-contact {
    margin-bottom: 680px;
}

@media only screen and (max-width: 600px) {
    .contact-img {
        background-image: url('../../images/contact-xs.png');
    }

    .header-contact {
        margin-bottom: 700px;
        background-color: #fdfdfd39;
    }

}

@media (min-width:600px) and (max-width:1000px) {}