.background-video {
    width: 100vw;
    overflow-x: hidden;
}

.just-video {
    width: 100vw;
    overflow-x: hidden;
    background-color: '#00000050';
}

video {
    height: 100%;
    width: 100vw;
    object-fit: fill;
    opacity: 80%;
}

.top-header {
    position: absolute;
    justify-content: center;
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    padding: 10px;
}

.home-works-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    padding: 0;
    justify-content: center;
    margin: 0;
    margin-top: 30px;
    margin-bottom: 100px;
}

.image-works {
    border-radius: 10px;
    height: 300px;
    width: 400px;
    padding: 0;
}