.ex-title {
    padding-top: 100px;
    padding-bottom: 20px;
    font-family: 'Oswald';
    font-size: 20px;
    letter-spacing: 1px;
    display: flex;
    justify-content: center;
    opacity: 0.5;
    flex-wrap: wrap;

}

.carousel-control-prev-icon {
    width: 40px;
    height: 40px;
}

.carousel-control-next-icon {
    width: 40px;
    height: 40px;
}

@media only screen and (max-width: 600px) {
    .ex-title {
        font-size: 14px;
        text-align: center;
        padding-bottom: 10px;
        padding-top: 80px;
    }

    .carousel-control-prev-icon {
        width: 20px;
        height: 20px;
    }

    .carousel-control-next-icon {
        width: 20px;
        height: 20px;
    }


}

@media (min-width:600px) and (max-width:1000px) {
    .ex-title {
        font-size: 18px;
        text-align: center;
        padding-bottom: 10px;
    }

    .carousel-control-prev-icon {
        width: 30px;
        height: 30px;
    }

    .carousel-control-next-icon {
        width: 30px;
        height: 30px;
    }
}