.title-detail {
    color: #000;
    font-size: 20px;
    display: flex;
    justify-content: center;
    margin-top: 4%;
    font-family: 'Oswald';
    letter-spacing: 2px;
    padding-bottom: 40px;
}

.title-detail-2 {
    color: #000;
    font-size: 20px;
    display: flex;
    justify-content: center;
    margin-top: 0;
    font-family: 'Oswald';
    letter-spacing: 2px;
    padding-bottom: 20px;
    opacity: 0.5;
}

.text-detail {
    font-size: 20px;
    padding-top: 50px;
    font-family: 'Quicksand';
    padding-left: 50px;
    padding-right: 50px;
    letter-spacing: 2px;
    text-align: justify;
    padding-bottom: 30px;
    padding-top: 30px;
    opacity: 0.5;
}

.other-img {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

}

.mailto {
    font-size: 18px;
    color: #000;
    font-family: "Oswald";
    text-align: center;
    letter-spacing: 2px;
    opacity: 0.7;
}

.mailto:hover {
    color: #fdfdfd;
    opacity: 0.4;
}

@media only screen and (max-width: 600px) {
    .title-detail {
        font-size: 16px;
        padding-bottom: 30px;
        text-align: center;
    }

    .text-detail {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 20px;
        font-size: 14px;

    }

    .title-detail-2 {
        font-size: 16px;
        text-align: center;
    }

    .mailto {
        font-size: 10px;
        text-decoration: underline;
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media (min-width:600px) and (max-width:1000px) {
    .title-detail {
        font-size: 18px;
        padding-bottom: 30px;
        text-align: center;
    }

    .text-detail {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 25px;
        font-size: 14px;

    }
}