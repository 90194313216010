.footer-text {
    font-size: 14px;
    font-family: 'Oswald';
    font-weight: 400;
    padding-top: 30px;
    padding-bottom: 30px;
    border-top: 1px solid #fff;
    color: #fff;
    opacity: 0.6;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.insta {
    margin-left: 20px;
}

.icon {
    font-size: 1rem;
    padding: 5px;
    color: #fff;
}

.icon:hover {
    color: black;
}

@media only screen and (max-width: 600px) {
    .footer-text {
        font-size: 12px;
    }

    .icon {
        font-size: 12px;
    }

    .insta {
        margin-left: 10px;
    }
}

@media (min-width:600px) and (max-width:1000px) {}